@import '../theme-bootstrap';

.sd-mount-skeleton {
  position: relative;
  .sd-grid-skeleton {
    padding: 0 0 25px;
    @media #{$cr19-large-up} {
      padding: 0;
    }
    .elc-shade-swatches-wrapper {
      margin-top: -3px;
    }
    .elc-rating-add-to-bag-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media #{$cr19-medium-up} {
        display: none;
      }
      .elc-stars-simplified::before {
        height: 45px;
        background-position: center;
      }
    }
    .elc-add-to-bag {
      @include text-link--style-1;
      background: none;
      border: none;
      text-align: $rdirection;
      cursor: pointer;
      display: inline-block;
      height: 45px;
      &:hover {
        background: none;
        border: none;
      }
      &::after {
        content: '';
        direction: ltr;
        height: 18px;
        padding: 3px 12px;
        margin-#{$ldirection}: 5px;
        background: url('#{$base-theme-path}/img/icons/src/bag-with-plus.svg') no-repeat center;
      }
    }
    .skeleton-details {
      align-items: flex-start;
    }
    .skeleton-shades-grid {
      display: flex;
      .elc-shade-image-wrapper {
        will-change: auto;
        width: 70px;
        @media #{$cr19-medium-up} {
          width: 35px;
        }
        @media #{$cr19-large-up} {
          width: 70px;
        }
      }
    }
    .elc-grid-column {
      display: none;
    }
    .elc-size-picker-container {
      display: flex;
      order: unset;
      border: none;
      overflow: hidden;
      @media #{$cr19-medium-up} {
        display: none;
      }
      .elc-size {
        @include swap_direction(margin, 12px 12px 12px 0);
        border: 2px solid $cr19-text-grey;
        border-radius: 0;
        color: $cr19-text-grey;
        width: auto;
        white-space: nowrap;
        text-align: center;
        padding: 8px 12px;
        cursor: pointer;
      }
    }
    .elc-product-name-wrapper {
      padding-top: 10px;
    }
    .elc-product-images-wrapper {
      img {
        height: auto;
        max-width: 100%;
        background-color: $cr19-bg-light;
      }
    }
    .skeleton-details {
      .elc-product-display-name-link {
        @include text-body-text--no-xl;
        color: $color-cl-light-black;
      }
    }
    .sd-grid-skeleton-items {
      .skeleton-item {
        width: auto;
        height: auto;
      }
      &.is-slider {
        @media #{$cr19-medium-up} {
          margin: 0 -12px;
        }
        .skeleton-item {
          padding: 0;
          margin: 0;
          position: relative;
          @media #{$cr19-medium-up} {
            padding: 0 12px;
          }
          &.elc-grid-item-tout {
            margin-inline-end: 20px;
            aspect-ratio: 9/16;
            @media #{$cr19-large-up} {
              margin-inline-end: 4px;
            }
          }
        }
      }
    }

    .sd-grid-skeleton-filters {
      padding: 0;
      gap: 25px;
      width: 100%;
      @media #{$cr19-medium-up} {
        width: auto;
      }
      .skeleton-bar {
        height: 40px;
        &-filters,
        &-sort {
          max-width: 100%;
        }
        &-sort {
          height: 58px;
          @media #{$cr19-medium-up} {
            position: absolute;
            #{$rdirection}: 0;
            top: 0;
            width: 15%;
          }
          @media #{$cr19-large-up} {
            height: 40px;
          }
        }
        &-filters {
          @include shimmer(1.5s, ease-in-out);
          height: 58px;
          @media #{$cr19-medium-up} {
            min-height: 126px;
            width: 100%;
          }
          & + .skeleton-bar-sort {
            float: $rdirection;
            width: calc(50% - 10px);
            @media #{$cr19-medium-up} {
              width: 15%;
            }
          }
        }
      }
    }
    .sd-grid-skeleton-filters {
      & + .sd-grid-skeleton-items {
        margin-top: 44px;
        top: auto;
        width: 100%;
        @media #{$cr19-medium-portrait-only} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        }
        @media #{$cr19-large-up} {
          grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }
      }
    }
    &.has-filters {
      flex-direction: column;
      @media #{$cr19-medium-up} {
        flex-direction: row;
      }
      .skeleton-item {
        padding: 10px 0 20px;
        @media #{$cr19-medium-up} {
          padding: 8px 8px 40px;
        }
        @media #{$cr19-large-up} {
          padding: 12px 12px 40px;
        }
      }
      .skeleton-box {
        margin: 10px 0;
        min-height: 450px;
        @media #{$cr19-medium-up} {
          margin: 0 8px;
          min-height: auto;
        }
        @media #{$cr19-large-up} {
          margin: 12px;
        }
      }
      .elc-product-image-badge-wrapper {
        position: relative;
      }
      .sd-grid-skeleton-filters {
        @media #{$cr19-medium-up} {
          flex: 25%;
          @include swap_direction(padding, 0 16px 0 0);
        }
        @media #{$cr19-large-up} {
          @include swap_direction(padding, 0 24px 0 0);
        }
      }
      .sd-grid-skeleton-items {
        position: relative;
        @media #{$cr19-medium-portrait-only} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
          margin: 0 -8px;
          flex: 75%;
        }
        @media #{$cr19-medium-up} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
          float: $rdirection;
          margin-top: 70px;
          flex: 75%;
          margin: 70px -8px 0;
        }
        @media #{$cr19-large-up} {
          grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));
          float: $rdirection;
          flex: 75%;
          margin: 70px -12px 0;
        }
      }
    }
  }
}
